const PersonIcon = () => (
  <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.945312" width="34" height="34" rx="17" fill="white" fill-opacity="0.2"/>
  <rect x="1.44531" y="0.5" width="33" height="33" rx="16.5" stroke="url(#paint0_linear_11311_21072)" stroke-opacity="0.25"/>
  <path d="M22.2953 22.8125V23.7125H24.0953V22.8125H22.2953ZM20.5703 18.875V17.975V18.875ZM15.3203 18.875V17.975V18.875ZM12.6953 21.5H11.7953H12.6953ZM11.7953 22.8125V23.7125H13.5953V22.8125H11.7953ZM24.0953 22.8125V21.5H22.2953V22.8125H24.0953ZM24.0953 21.5C24.0953 20.5651 23.7239 19.6685 23.0629 19.0074L21.7901 20.2802C22.1136 20.6037 22.2953 21.0425 22.2953 21.5H24.0953ZM23.0629 19.0074C22.4018 18.3464 21.5052 17.975 20.5703 17.975V19.775C21.0278 19.775 21.4666 19.9567 21.7901 20.2802L23.0629 19.0074ZM20.5703 17.975H15.3203V19.775H20.5703V17.975ZM15.3203 17.975C14.3854 17.975 13.4888 18.3464 12.8278 19.0074L14.1006 20.2802C14.4241 19.9567 14.8628 19.775 15.3203 19.775V17.975ZM12.8278 19.0074C12.1667 19.6685 11.7953 20.5651 11.7953 21.5H13.5953C13.5953 21.0425 13.7771 20.6037 14.1006 20.2802L12.8278 19.0074ZM11.7953 21.5V22.8125H13.5953V21.5H11.7953ZM19.6703 13.625C19.6703 14.5777 18.898 15.35 17.9453 15.35V17.15C19.8921 17.15 21.4703 15.5718 21.4703 13.625H19.6703ZM17.9453 15.35C16.9926 15.35 16.2203 14.5777 16.2203 13.625H14.4203C14.4203 15.5718 15.9985 17.15 17.9453 17.15V15.35ZM16.2203 13.625C16.2203 12.6723 16.9926 11.9 17.9453 11.9V10.1C15.9985 10.1 14.4203 11.6782 14.4203 13.625H16.2203ZM17.9453 11.9C18.898 11.9 19.6703 12.6723 19.6703 13.625H21.4703C21.4703 11.6782 19.8921 10.1 17.9453 10.1V11.9Z" fill="white"/>
  <defs>
  <linearGradient id="paint0_linear_11311_21072" x1="0.945312" y1="34" x2="34.9453" y2="34" gradientUnits="userSpaceOnUse">
  <stop stop-color="white"/>
  <stop offset="0.35" stop-color="white" stop-opacity="0"/>
  <stop offset="0.75" stop-color="white" stop-opacity="0"/>
  <stop offset="1" stop-color="white"/>
  </linearGradient>
  </defs>
  </svg>
  
)

export default PersonIcon;
