import { Component, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { SWRConfig, SWRConfiguration } from 'swr';
import { Loader } from 'google-maps-js-api-react';
import { QueryParamOptions, QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';
import isEqual from 'lodash/isEqual';

import { getUser } from 'src/store/user/actions';
import { initApp } from 'src/store/app/actions';
import config from 'src/config';
import { ConnectedIntl } from 'src/containers/ConnectedIntl';
import { GlobalStyle } from 'src/components/common/GlobalStyle';
import HeaderPanel from 'src/components/Panels/HeaderPanel';
import FooterPanel from 'src/components/Panels/FooterPanel';
import MainLoader from 'src/components/MainLoader';
import { DeviceProvider } from 'src/modules/DeviceProvider';
import { ReduxState } from 'src/store/reducers';
// import { CookiesBanner } from 'src/components/Banners/CookiesBanner';
import { appConfig } from 'src/modules/app-config';

import AuthModalProvider from '../Auth/AuthModalProvider';
import { MobileAppModal } from '../Modals/mobile-app-modal';
import { CookiesBanner } from '../Banners/CookiesBanner';

import { routes, PickedRouteProps } from './routes';
// import { UkraineBanner } from './UkraineBanner';

const AppWrap = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 100vh;
  background: ${(p) => p.theme.custom.whiteMainColor};
`;

const MainWrap = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  flex-grow: 1;
  background: ${(p) => p.theme.custom.backgroundMain};
`;

interface AppProps {
  intl: ReduxState['intl'];
  isSettingsFetched: boolean;
  location: string;
  isAuthenticated: boolean;
  initApp: () => void;
  getUser: () => void;
}

const swrConfig: Partial<SWRConfiguration> = {
  shouldRetryOnError: false,
  revalidateOnFocus: false,
  revalidateIfStale: false,
  compare: isEqual,
};

Loader.options = {
  version: '3',
  libraries: ['places', 'geometry'],
  apiKey: appConfig('GOOGLE_API_KEY'),
  defer: true,
};

const opt: QueryParamOptions = {
  searchStringToObject: (q) => parse(q, { arrayFormat: 'comma', sort: false }),
  objectToSearchString: (o) =>
    stringify(o, { arrayFormat: 'comma', sort: false }),
};

class App extends Component<AppProps, any, any> {
  componentDidMount() {
    const { isAuthenticated } = this.props;

    this.props.initApp();

    if (isAuthenticated) {
      this.props.getUser();
    }
  }

  componentDidUpdate(prevProps: any) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { intl, isSettingsFetched } = this.props;
    const { currentLocale } = intl;
    const messages = intl.messages[currentLocale];

    if (!messages || !isSettingsFetched) {
      return null;
    }

    return (
      <>
        <GlobalStyle />
        <ConnectedIntl>
          <Helmet
            defer={false}
            encodeSpecialCharacters={true}
            defaultTitle={config.title}
            titleTemplate={`${config.title} | %s`}
            titleAttributes={{ itemprop: 'name', lang: currentLocale }}
          >
            <html lang={currentLocale} />
          </Helmet>
          <AppWrap>
            <AuthModalProvider>
              <SWRConfig value={swrConfig}>
                <DeviceProvider>
                  <HeaderPanel />
                  {/* <UkraineBanner /> */}
                  <MainWrap>
                    <QueryParamProvider
                      adapter={ReactRouter5Adapter}
                      options={opt}
                    >
                      <Suspense fallback={<MainLoader />}>
                        <Switch>{this.renderRoutes(routes)}</Switch>
                      </Suspense>
                    </QueryParamProvider>
                  </MainWrap>
                  <FooterPanel />
                  <CookiesBanner />
                </DeviceProvider>
              </SWRConfig>
            </AuthModalProvider>
          </AppWrap>
        </ConnectedIntl>
        {/* <MobileAppModal /> */}
      </>
    );
  }

  private renderRoutes = (routes: PickedRouteProps[]) =>
    routes.map((route, index) => <Route key={index} {...route} />);
}

/* istanbul ignore next */
const mapStateToProps = (state: ReduxState) => ({
  intl: state.intl,
  isSettingsFetched: state.settings.fetched,
  location: state.router.location.pathname,
  isAuthenticated: state.user.isAuthenticated,
});

const mapDispatchToProps = {
  initApp,
  getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
