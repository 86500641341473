import { FC, memo } from 'react';
import styled from 'styled-components/macro';
import _isNil from 'lodash/isNil';
import { Stack } from '@mui/material';

import { DataElementsId } from 'src/constants';
import TwoColumnLayout, {
  LeftColumn,
  RightColumn,
} from 'src/components/common/TwoColumnLayout';
import { fontSizeBase, fontSizeExtraLarge } from 'src/modules/fontSizes';
import { media } from 'src/modules/mediaQuery';
import { I18n } from 'src/components/I18n';
import { TextDivider } from 'src/components/TextDivider';
import { Phone, TabletDesktop } from 'src/components/shared/CResponsive';
import { getHotelAddress } from 'src/modules/helpers';
import { HotelRate } from 'src/components/common/HotelRate';
import { TrustYou } from 'src/components/HotelItem/TrustYou';
import FavoriteButton from 'src/components/FavoriteButton';
import { HotelPreviewSize } from 'src/components/HotelPreview/type';
import { HotelPreview } from 'src/components/HotelPreview';
import useToggler from 'src/hooks/useToggler';
import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';
import useFormatDistance from 'src/hooks/useFormatDistance';
import useHotelCardData from 'src/components/HotelCard/useHotelCardData';

import { TrustScoreWidget } from './TrustScoreWidget';
import Map from './Map';

const Wrapper = styled.div``;

const StyledHotelPreview = styled(HotelPreview)`
  height: 408px;

  ${media.tablet`
    height: 300px;
  `}
`;

const InfoWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;

  ${media.desktop`
    padding-top: 0;
  `}
`;

const HotelName = styled.h3`
  display: flex;
  gap: 8px;
  align-items: center;
  ${fontSizeExtraLarge};
  line-height: 20px;
  margin: 0 10px 0 0;

  ${media.phone`
    font-size: 20px;
  `}

  ${media.phoneTablet`
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 8px;
  `}
`;

const StyledTrustYou = styled(TrustYou)`
  margin: 0 0 10px 10px;
`;

const AddressWrapper = styled.div`
  color: ${(p) => p.theme.custom.grayTextColor};
  ${fontSizeBase};
  line-height: 18px;
  margin-top: 5px;
`;

const DiscountLabel = styled.div`
  padding: 4px 12px;
  background: black;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
`

const Address = styled.span``;

const StyledFavoriteButton = styled(FavoriteButton)`
  position: static;
`;

const MapIcon = styled.span.attrs({ className: 'icon-map' })`
  margin-right: 5px;
`;

const ReviewsCount = styled.span`
  color: #323232;
  font-size: 12px;
`

const ReviewsStatus = styled.span`
  color: #00874E;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
`

const RatingContainer = styled.div`
  position: relative;
  width: 45px;
  height: 44px;
  display: flex;
  justify-content: center;
`;

const RatingNumber = styled.span`
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  line-height: 24px;
  z-index: 10;
  position: relative;
  margin-top: 5px;
`

const RatingIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`

const ReviewContainer = styled.div`
  display: flex;
  margin-top: 24px;
`

interface GeneralInfoProps {
  data: NonNullable<HotelInfoSelectorResult>;
  sectionRef?: (el: HTMLDivElement | null) => void;
}

const GeneralInfo: FC<GeneralInfoProps> = memo(
  ({ data, sectionRef }) => {
    const { distanceToCenter } = data as any;
    const showDistanceToCenter = !_isNil(distanceToCenter);
    const { formatReviewsCount } = useHotelCardData();

    const formatDistance = useFormatDistance();

    const { toggler, handleClose, handleOpen } = useToggler();

    return (
      <>
        <Wrapper ref={sectionRef} data-id="overview">
          <TabletDesktop>
            <StyledHotelPreview
              size={HotelPreviewSize.LARGE}
              images={data.images}
              imagesCount={data.imagesCount}
              hotelId={data.id}
              withGallery={true}
              trackingId={DataElementsId.DETAIL_HOTEL_PREVIEW_BUTTON}
              onMapButtonClick={handleOpen}
            />
          </TabletDesktop>
          <Phone>
            <HotelPreview
              size={HotelPreviewSize.MEDIUM}
              images={data.images}
              imagesCount={data.imagesCount}
              withGallery={true}
              hotelId={data.id}
              showMapButton={true}
              onMapButtonClick={handleOpen}
            />
          </Phone>
          <InfoWrapper>
            <TwoColumnLayout>
              <LeftColumn>
                <HotelRate value={data.stars} />
                <StyledTrustYou data={data} />
                <HotelName data-test-id="HOTEL_GENERAL_INFO_NAME">
                  {data.name}

                  {data.discount ? (
                    <DiscountLabel>
                      -{data.discount}% OFF
                    </DiscountLabel>
                  ) : null}
                </HotelName>
                <AddressWrapper>
                  <MapIcon />
                  <Address>{getHotelAddress(data)}</Address>
                  {showDistanceToCenter && (
                    <>
                      <TextDivider />
                      <I18n
                        id="HOTEL_CARD.DISTANCE_TO_CENTER_LABEL"
                        values={{
                          distanceToCenter: formatDistance(distanceToCenter),
                        }}
                      />
                    </>
                  )}
                </AddressWrapper>
              </LeftColumn>
              <RightColumn>
                <Phone>
                  {/* <StyledFavoriteButton hotelId={data.id} /> */}
                </Phone>
                <TabletDesktop>
                  {data.review ? (
                    <ReviewContainer>                    
                      <Stack spacing={2} direction="row" gap={4}>
                        <RatingContainer>
                          <RatingIcon>
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="42" viewBox="0 0 44 42" fill="none">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0.5C1.79086 0.5 0 2.29086 0 4.5V34.5V41.5L12.0001 34.5H40C42.2091 34.5 44 32.7091 44 30.5V0.5H4Z" fill="#00874E"/>
                            </svg>
                          </RatingIcon>
                          
                          <RatingNumber>
                            {data.review.score}
                          </RatingNumber>
                        </RatingContainer>
                        <Stack direction="column" alignItems="start">
                          <ReviewsStatus>
                            <I18n id={`HOTEL_RATING.${data.review.category}`} />
                          </ReviewsStatus>
                          <ReviewsCount>
                            <I18n
                              id="HOTEL_CARD.REVIEWS_COUNTS"
                              values={{ count: formatReviewsCount(data.review.reviewsCount) }}
                            />
                          </ReviewsCount>
                        </Stack>
                      </Stack>
                    </ReviewContainer>
                  ) : null}
                </TabletDesktop>
              </RightColumn>
            </TwoColumnLayout>
            <Phone>
              {data.review ? (
                <ReviewContainer>                    
                  <Stack spacing={2} direction="row" gap={2}>
                    <RatingContainer>
                      <RatingIcon>
                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="42" viewBox="0 0 44 42" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0.5C1.79086 0.5 0 2.29086 0 4.5V34.5V41.5L12.0001 34.5H40C42.2091 34.5 44 32.7091 44 30.5V0.5H4Z" fill="#00874E"/>
                        </svg>
                      </RatingIcon>
                      
                      <RatingNumber>
                        {data.review.score}
                      </RatingNumber>
                    </RatingContainer>
                    <Stack direction="column" alignItems="start">
                      <ReviewsStatus>
                        <I18n id={`HOTEL_RATING.${data.review.category}`} />
                      </ReviewsStatus>
                      <ReviewsCount>
                        <I18n
                          id="HOTEL_CARD.REVIEWS_COUNTS"
                          values={{ count: formatReviewsCount(data.review.reviewsCount) }}
                        />
                      </ReviewsCount>
                    </Stack>
                  </Stack>
                </ReviewContainer>
              ) : null}
            </Phone>
          </InfoWrapper>
          <Map isOpen={toggler} onClose={handleClose} info={data} />
        </Wrapper>
      </>
    );
  }
);

export default GeneralInfo;
