const LocationIcon = () => (
  <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.945312" width="30" height="30" rx="15" fill="white" fill-opacity="0.2"/>
  <rect x="1.37902" y="0.433706" width="29.1326" height="29.1326" rx="14.5663" stroke="url(#paint0_linear_11311_21053)" stroke-opacity="0.25" stroke-width="0.867411"/>
  <path d="M21.2785 13.6673C21.2785 17.8155 15.9451 21.371 15.9451 21.371C15.9451 21.371 10.6118 17.8155 10.6118 13.6673C10.6118 12.2528 11.1737 10.8963 12.1739 9.89608C13.1741 8.89589 14.5307 8.33398 15.9451 8.33398C17.3596 8.33398 18.7162 8.89589 19.7164 9.89608C20.7166 10.8963 21.2785 12.2528 21.2785 13.6673Z" stroke="white" stroke-width="1.56134" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.9451 15.4451C16.927 15.4451 17.7229 14.6492 17.7229 13.6673C17.7229 12.6855 16.927 11.8895 15.9451 11.8895C14.9633 11.8895 14.1674 12.6855 14.1674 13.6673C14.1674 14.6492 14.9633 15.4451 15.9451 15.4451Z" stroke="white" stroke-width="1.56134" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
  <linearGradient id="paint0_linear_11311_21053" x1="0.945312" y1="30" x2="30.9453" y2="30" gradientUnits="userSpaceOnUse">
  <stop stop-color="white"/>
  <stop offset="0.35" stop-color="white" stop-opacity="0"/>
  <stop offset="0.75" stop-color="white" stop-opacity="0"/>
  <stop offset="1" stop-color="white"/>
  </linearGradient>
  </defs>
  </svg>
)

export default LocationIcon;
