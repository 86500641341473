import { VFC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import styled from 'styled-components/macro';

import { login, resetAccountErrorCode } from 'src/store/user/actions';
import { I18n } from 'src/components/I18n';
import {
  emailValidation,
  passwordRequired,
} from 'src/components/shared/FormElements/utils';
import { userSelector } from 'src/store/user/selectors';
import { useIntlFormatMessage } from 'src/hooks/useIntlFormatMessage';
import { AuthStep, LoadingTypes } from 'src/constants';
import { PasswordField } from 'src/components/common/PasswordField';
import { Link } from 'src/components/common/Link';

import { AuthBlockProps, EAuth } from '..';
import { ContentForm } from '../ContentForm/ContentForm';
import { handleModal } from '../utils';

const SignUpWrapper = styled.div`
  text-align: center;
`

const validationSchema = yup.object().shape({
  email: emailValidation,
  password: passwordRequired,
});

export const SignInSection: VFC<AuthBlockProps> = ({
  setActiveModal,
  onSubmit,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { errorCode, isAuthenticated, status } = useSelector(userSelector);

  useEffect(() => {
    if (isAuthenticated && onSubmit) onSubmit();
  }, [isAuthenticated]);

  useEffect(
    () => () => {
      dispatch(resetAccountErrorCode());
    },
    [dispatch]
  );

  const { handleSubmit, control } = useForm<{
    email: string;
    password: string;
  }>({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: '', password: '' },
  });

  const intlFormatMessage = useIntlFormatMessage();

  return (
    <ContentForm
      {...rest}
      loading={status === LoadingTypes.RUNNING}
      headerTextId="SIGN_IN_FORM.MODAL.HEADER_NAME"
      submitButtonI18nId="SIGN_IN_FORM.MODAL.BUTTON_NAME"
      alertCode={errorCode}
      authStep={AuthStep.SIGN_IN}
      onSubmit={handleSubmit((values) => {
        dispatch(login(values));
      })}
      navigateBlock={
        <>
          <Link
            to={`/${EAuth.RESET_PASSWORD}`}
            onClick={handleModal(setActiveModal, EAuth.RESET_PASSWORD)}
            color="secondary"
            variant="body4"
          >
            <I18n id="SIGN_IN_FORM.MODAL.FORGOT_PASSWORD" />
          </Link>
          <SignUpWrapper>
            <Typography variant="body4">
              <I18n id="SIGN_IN_FORM.MODAL.FOOTER.SIGN_TEXT" />
            </Typography>{' '}
            <Link
              to={`/${EAuth.SIGN_UP}`}
              onClick={handleModal(setActiveModal, EAuth.SIGN_UP)}
              color="secondary"
              variant="body4"
            >
              <I18n id="SIGN_UP_FORM.CLICK_NAME" />
            </Link>
          </SignUpWrapper>
        </>
      }
    >
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            placeholder={intlFormatMessage(
              'SIGN_IN_FORM.EMAIL_FIELD.PLACEHOLDER'
            )}
            color="focusSecondary"
            fullWidth
            error={Boolean(error)}
            helperText={error?.message}
            label={<I18n id="SIGN_IN_FORM.EMAIL_FIELD.LABEL" />}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <PasswordField
            {...field}
            placeholder={intlFormatMessage(
              'SIGN_IN_FORM.MODAL.PASSWORD.PLACEHOLDER'
            )}
            fullWidth
            color="focusSecondary"
            error={Boolean(error)}
            helperText={error?.message}
            label={<I18n id="SIGN_IN_FORM.MODAL.PASSWORD.LABEL" />}
          />
        )}
      />
    </ContentForm>
  );
};
