export const userNavMenu = [
  {
    url: '/account/bookings',
    label: 'Bookings',
  },
  {
    url: '/account/wallet-balance',
    label: 'Wallet Balance',
  },
  {
    url: '/account/favorites',
    label: 'Favourites',
  },
  {
    url: '/account/personal-info',
    label: 'Personal information',
  },
  {
    url: '/account/settings',
    label: 'Account settings',
  },
];
