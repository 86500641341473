import { VFC } from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components/macro';

import { I18n } from 'src/components/I18n';
import { appConfig } from 'src/modules/app-config';

import image from '../../../assets/sign-in-banner/img2.png';

const Image = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    object-position: center;
`;


const ImageWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BannerWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const TextWrapper = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  /* font-weight: 600; */
  font-size: 70px;
  line-height: 1.4;
  text-align: center;
`;

const StyledSubTitle = styled(Typography)`
  margin-top: 10px;
  width: 490px;
  color: ${({ theme }) => theme.palette.common.white};
  text-align: center;
`;

const imgPath = `https://${appConfig(
  'CDN_HOST'
)}/static/images/Signup/register.jpg`;

const Banner: VFC = () => (
  <BannerWrapper>
    <TextWrapper>
    </TextWrapper>
    <ImageWrapper>
      <Image alt="auth-banner" src={image} />
    </ImageWrapper>
  </BannerWrapper>
);

export default Banner;
