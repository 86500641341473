const CalendarIcon = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.945312" width="32" height="32" rx="16" fill="white" fill-opacity="0.2"/>
  <rect x="1.44531" y="0.5" width="31" height="31" rx="15.5" stroke="url(#paint0_linear_11311_21060)" stroke-opacity="0.25"/>
  <path d="M19.3155 10V12.3704M14.5748 10V12.3704M11.6118 14.7407H22.2785M12.797 11.1852H21.0933C21.7479 11.1852 22.2785 11.7158 22.2785 12.3704V20.6667C22.2785 21.3212 21.7479 21.8519 21.0933 21.8519H12.797C12.1424 21.8519 11.6118 21.3212 11.6118 20.6667V12.3704C11.6118 11.7158 12.1424 11.1852 12.797 11.1852Z" stroke="white" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
  <linearGradient id="paint0_linear_11311_21060" x1="0.945312" y1="32" x2="32.9453" y2="32" gradientUnits="userSpaceOnUse">
  <stop stop-color="white"/>
  <stop offset="0.35" stop-color="white" stop-opacity="0"/>
  <stop offset="0.75" stop-color="white" stop-opacity="0"/>
  <stop offset="1" stop-color="white"/>
  </linearGradient>
  </defs>
  </svg>
)

export default CalendarIcon;
