import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import LinksStack from 'src/components/common/LinksStack';
import { I18n } from 'src/components/I18n';
import { USER_NAV_MENU } from 'src/components/Panels/constants';
import joinBy from 'src/modules/joinBy';
import { logOut } from 'src/store/user/actions';
import { userSelector } from 'src/store/user/selectors';
import useConfig from 'src/hooks/swr/useConfig';

import { ContentProps } from '..';

const LoyaltyWrapper = styled.div`
  color: #0B0B0B;
  font-weight: 500;
  font-size: 17px;
  line-height: 140%;
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 4px;
`

const LoyaltyNew = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  padding: 2px 10px;
  background: #0B0B0B;
  border-radius: 30px;
`

const AffiliateBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  margin-top: 20px !important;
`

export const Content: VFC<ContentProps> = ({ onClose }) => {
  const { info } = useSelector(userSelector);
  const { data: config } = useConfig();
  const dispatch = useDispatch();
  const handleLogout = () => dispatch(logOut());
  const handleOpenDashboard = () => {
    dispatch(push('/affiliate-dashboard'));
    onClose();
  };
  const handleOpenLoyaltyPage = () => {
    dispatch(push('/account/loyalty'));
    onClose();
  };

  const blocks = [
    <LinksStack
      links={USER_NAV_MENU}
      onLinkClick={onClose}
      spacing={4}
      key="links"
      typography="label1"
    />,
    <AffiliateBlock
      key="affiliate-dashboard"
    >
      {config?.featureToggles.ShowLoyaltyProgram ? (
        <Link
          color="buttonSecondary"
          typography="label1"
          onClick={handleOpenLoyaltyPage}
          component="button"
        >
          <LoyaltyWrapper>
            <LoyaltyNew>
              NEW
            </LoyaltyNew>
            <I18n id="MAIN_NAV_MENU.LOYALTY_PROGRAM" />
          </LoyaltyWrapper>
        </Link>
      ) : null}
      
      {config?.featureToggles?.ShowAffiliateDashboard && (
        <Link
        color="buttonSecondary"
        typography="label1"
        onClick={handleOpenDashboard}
        component="button"
      >
        <I18n id="MAIN_NAV_MENU.AFFILIATE_DASHBOARD" />
      </Link>
      )}
    </AffiliateBlock>,
    <div
      key="sign-out"
      css={`
        width: fit-content;
      `}
    >
      <Link
        color="buttonSecondary"
        typography="buttonLarge"
        onClick={handleLogout}
        component="button"
      >
        <I18n id="MAIN_NAV_MENU.LOGOUT_BTN" />
      </Link>
    </div>,
  ];

  const userInfo = [];
  const userNames = [] as string[];

  if (info) {
    if (info.name) {
      userNames.push(info.name);
    }
    if (info.surname) {
      userNames.push(info.surname);
    }
    if (userNames.length > 0) {
      userInfo.push(
        <Typography variant="h3" key="name">
          {userNames.join(' ')}
        </Typography>
      );
    }

    if (info.email)
      userInfo.push(
        <Typography variant="body2" key="email">
          {info.email}
        </Typography>
      );
  }

  if (userInfo.length) blocks.unshift(<div key="user-info">{userInfo}</div>);

  return (
    <Stack spacing={7} width={400}>
      {joinBy(blocks, <Divider />)}
    </Stack>
  );
};
