import styled from "styled-components"

export const MaintenanceContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url('https://images.pexels.com/photos/3369571/pexels-photo-3369571.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, sans-serif;
  position: relative;
  z-index: 1;
`

export const MaintenanceTitle = styled.div`
  font-size: 60px;
  color: #383838;
  font-weight: bold;
  text-align: center;
  z-index: 20;
  
  @media (max-width: 1200px) {
    font-size: 36px;
  }

  @media (max-width: 556px) {
    font-size: 28px;
  }
`

export const LogoContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 20;
`

export const Backdrop = styled.div`
  background: black;
  opacity: 0.5;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10;
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  background-color: #ffffff8b;
  padding: 32px;
  border-radius: 16px;
  margin: 16px;
  z-index: 20;
  max-width: 1000px;

  @media (max-width: 980px) {
    padding: 32px 16px;
  }

  @media (max-width: 556px) {
    padding: 16px;
  }
`
