import { VFC, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useWatch } from 'react-hook-form';

import { I18n } from 'src/components/I18n';
import { SearchParamsState, Room } from 'src/store/search/reducers';
import useToggler from 'src/hooks/useToggler';
import PersonIcon from 'src/assets/icons/person';

import { SearchPanelPopover } from '../../SearchPanelPopover';
import { FieldsName, SearchPanelInputProps } from '../..';
import { SearchPanelInput } from '../../SearchPanelInput';
import { AppliedDrawer } from '../../AppliedDrawer';

import { RoomChooserForm } from './RoomChooserForm';

const Dot = styled.span`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.text.primary};
  margin: 0 5px;
`;

const StyledLabel = styled.div`
  color: #fff;
`

const RoomsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const StyledContrastLabel = styled.div`
  color: #767E83;
`

export const getRoomsValues = (rooms: Room[]) =>
  rooms.reduce(
    (res, room) => ({
      ...res,
      adults: res.adults + room.adults,
      children: res.children + room.children.length,
    }),
    { rooms: rooms.length, adults: 0, children: 0, dot: () => <Dot /> }
  );

export const RoomChooser: VFC<SearchPanelInputProps> = ({
  isMobile,
  handleIsOpen,
  openDefault,
  isContrast,
}) => {
  const rooms = useWatch<SearchParamsState, FieldsName.ROOMS>({
    name: FieldsName.ROOMS,
  });

  const anchorElRef = useRef<HTMLDivElement>(null);

  const inputLabelRef = useRef<HTMLLabelElement>(null);

  const popoverRef = useRef<HTMLDivElement>(null);

  const { toggler, handleOpen, handleClose } = useToggler(
    handleIsOpen && {
      onOpen: () => handleIsOpen(true),
      onClose: () => handleIsOpen(false),
    }
  );

  useEffect(() => {
    if (openDefault && !isMobile && !toggler) handleOpen();
  }, []);

  const form = <RoomChooserForm />;

  return (
    <RoomsContainer ref={anchorElRef}>
      {isContrast ? <PersonIcon /> : null}
      <SearchPanelInput
        label={
          isContrast ?
          <StyledLabel>
            <I18n id="HOME_PAGE.SEARCH_PANEL.ROOM_CHOOSER.GUESTS" />
          </StyledLabel>
          : <I18n id="HOME_PAGE.SEARCH_PANEL.ROOM_CHOOSER.GUESTS" />
        }
        fullWidth
        focused={toggler}
        InputLabelProps={{ ref: inputLabelRef }}
        onClick={handleOpen}
        isContrast={isContrast}
      >
        <I18n
          id="HOME_PAGE.SEARCH_PANEL.ROOM_CHOOSER.SUMMARY"
          values={getRoomsValues(rooms)}
        />
      </SearchPanelInput>
      {isMobile ? (
        <AppliedDrawer
          fieldName={FieldsName.ROOMS}
          open={toggler}
          onClose={() => handleClose()}
          header={<I18n id="HOME_PAGE.SEARCH_PANEL.ROOM_CHOOSER.GUESTS" />}
          buttonText={<I18n id="GENERAL_APPLY" />}
          withPadding
        >
          {form}
        </AppliedDrawer>
      ) : (
        <SearchPanelPopover
          open={toggler}
          anchorEl={anchorElRef.current}
          onClickOutside={handleClose}
          ref={popoverRef}
          arrowTargetRef={inputLabelRef}
        >
          {form}
        </SearchPanelPopover>
      )}
    </RoomsContainer>
  );
};
