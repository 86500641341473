import { useTheme } from 'styled-components/macro';
import { FC, FormEvent, ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import isNil from 'lodash/isNil';

import CvexLogo from 'src/assets/auth/cvex-logo.png';
import NordicLogo from 'src/assets/auth/nordic-logo.png';
import ByBitEntravelLogo from 'src/assets/auth/bybit-entravel-logo.png';
import { I18n } from 'src/components/I18n';
import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';
import {
  ErrorCode,
  getErrorCodeNameBy,
} from 'src/components/shared/FormElements/types';
import LogoLarge from 'src/assets/home-page/logo';
import Logo from 'src/components/common/Logo';
import useMatchMedia from 'src/hooks/useMatchMedia';
import { AuthStep } from 'src/constants';
import useConfig from 'src/hooks/swr/useConfig';

import {
  ContentContainer,
  CvexDescription,
  CvexHeadingContainer,
  CvexTitle,
  Divider,
  FormContainer,
  FormTitle,
  Image,
  LogoWrapper,
  StyledSubmitButton,
  SubmitButtonText,
} from './styles';

export type ContentFormProps = {
  hideOAuth?: boolean;
  cvexCampaign?: boolean;
  nordicCampaign?: boolean;
  byBitCampaign?: boolean;
  ethermailCampaign?: boolean;
  onSubmit: () => void;
  navigateBlock?: ReactNode;
  termsBlock?: ReactNode;
  alertCode?: ErrorCode | null;
  headerTextId: DefaultAppMessagesTypeKey;
  submitButtonI18nId: DefaultAppMessagesTypeKey;
  loading: boolean;
  subscription?: boolean;
  authStep: AuthStep;
};

export const renderAlert = (alertCode: ErrorCode) => {
  if (alertCode === ErrorCode.NO_ERROR){
    return (
      <Alert severity="success">
        <I18n id="RESET_PASSWORD_FORM.RESULTS.SUCCESS" />
      </Alert>
    );
  }

  return (
    <Alert severity="error">
      <I18n id={getErrorCodeNameBy(alertCode)} />
    </Alert>
  );
};

export const ContentForm: FC<ContentFormProps> = ({
  onSubmit,
  children,
  navigateBlock,
  termsBlock,
  loading,
  headerTextId,
  submitButtonI18nId,
  alertCode,
  authStep,
  cvexCampaign = false,
  nordicCampaign = false,
  byBitCampaign = false,
  ethermailCampaign = false
}) => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));
  const { data: config } = useConfig();
  // const isByBitIntegration = config?.partner === 'ByBit' && config.featureToggles.ShowByBit;

  const renderHeading = () => {
    if (cvexCampaign || nordicCampaign) {
      return (
        <CvexHeadingContainer>
          <CvexTitle>
            <I18n id='SIGN_UP_FORM.CVEX_CONGRATULATIONS' />
            <br />
            <I18n id='SIGN_UP_FORM.CVEX_UNLOCKED' />
          </CvexTitle>
          <CvexDescription>
            <I18n id='SIGN_UP_FORM.CVEX_DESCRIPTION' />
          </CvexDescription>
        </CvexHeadingContainer>
      )
    }

    if (byBitCampaign && authStep === AuthStep.SIGN_UP) {
      return (
        <CvexHeadingContainer>
          <CvexTitle>
            <I18n id='SIGN_UP_FORM.CVEX_CONGRATULATIONS' />
            <br />
            <I18n id='SIGN_UP_FORM.CVEX_UNLOCKED' />
          </CvexTitle>
          <CvexDescription>
            <I18n id='SIGN_UP_FORM.CVEX_DESCRIPTION' />
          </CvexDescription>
        </CvexHeadingContainer>
      )
    }

    return (
      <FormTitle>
        <I18n id={headerTextId} />
      </FormTitle>
    )
  }

  return (
    <ContentContainer>    
      <FormContainer>
        {cvexCampaign ? <Image alt="cvex-logo" src={CvexLogo} /> : null}
        {nordicCampaign ? <Image alt="nordic-logo" src={NordicLogo} /> : null}
        {/* {byBitCampaign || isByBitIntegration ? <Image alt="byBit-logo" src={ByBitEntravelLogo} /> : null} */}
        {/* {!nordicCampaign && !cvexCampaign && !byBitCampaign && !isByBitIntegration ? ( */}
        {!nordicCampaign && !cvexCampaign && !byBitCampaign ? (
          <LogoWrapper>
            {isMobile ? <Logo isContrast /> : <LogoLarge />}
          </LogoWrapper>
        ) : null}
        <Divider />

        {renderHeading()}

        <Stack spacing={7} width="100%">
          {!isNil(alertCode) && renderAlert(alertCode)}
          <Stack spacing={6} component="form" onSubmit={onSubmit}>
            {children}
            <StyledSubmitButton
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              loading={loading}
              loadingPosition="end"
              color="buttonSecondary"
            >
              <SubmitButtonText>
                <I18n id={submitButtonI18nId} />
              </SubmitButtonText>
            </StyledSubmitButton>
          </Stack>
          {termsBlock}

          {navigateBlock && (
            <Stack spacing={4} alignItems="center">
              {navigateBlock}
            </Stack>
          )}
        </Stack>
      </FormContainer>
    </ContentContainer>
)};
