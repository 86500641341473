export enum ActionTypes {
  INIT_APP = 'INIT_APP',
  SEARCH_PARAMS_CHANGE = 'SEARCH_PARAMS_CHANGE',
  UPDATE_LOCALE_REQUEST = 'UPDATE_LOCALE_REQUEST',
  UPDATE_LOCALE_SUCCESS = 'UPDATE_LOCALE_SUCCESS',
  UPDATE_LOCALE_FAILURE = 'UPDATE_LOCALE_FAILURE',
  FETCH_HOTEL_ROOMS_REQUEST = 'FETCH_HOTEL_ROOMS_REQUEST',
  FETCH_HOTEL_ROOMS_SUCCESS = 'FETCH_HOTEL_ROOMS_SUCCESS',
  FETCH_HOTEL_ROOMS_FAILURE = 'FETCH_HOTEL_ROOMS_FAILURE',
  STOP_HOTEL_ROOMS_POLLING = 'STOP_HOTEL_ROOMS_POLLING',
  FETCH_HOTEL_REQUEST = 'FETCH_HOTEL_REQUEST',
  FETCH_HOTEL_SUCCESS = 'FETCH_HOTEL_SUCCESS',
  FETCH_HOTEL_FAILURE = 'FETCH_HOTEL_FAILURE',
  FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS',
  FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE',
  SUBMIT_CONTACT_FORM_REQUEST = 'SUBMIT_CONTACT_FORM_REQUEST',
  SUBMIT_CONTACT_FORM_SUCCESS = 'SUBMIT_CONTACT_FORM_SUCCESS',
  SUBMIT_CONTACT_FORM_FAILURE = 'SUBMIT_CONTACT_FORM_FAILURE',
}

export enum DeprecatedTypes {
  SET_DEPRECATED = 'SET_DEPRECATED',
}

export enum PopUpActionTypes {
  CHANGE_INFO_POP_UP_VIEW = 'CHANGE_INFO_POP_UP_VIEW',
  CHANGE_CHECKOUT_POP_UP = 'CHANGE_CHECKOUT_POP_UP',
}

export enum CurrencyActionTypes {
  UPDATE_CURRENCY = 'UPDATE_CURRENCY',
  INIT_CURRENCY = 'INIT_CURRENCY',
}

export enum CheckoutActionTypes {
  PAYBYRD_3DS_CHECK = 'PAYBYRD_3DS_CHECK',
  CREATE_CHECKOUT_REQUEST = 'CREATE_CHECKOUT_REQUEST',
  CREATE_CHECKOUT_SUCCESS = 'CREATE_CHECKOUT_SUCCESS',
  CREATE_CHECKOUT_FAILURE = 'CREATE_CHECKOUT_FAILURE',
  FETCH_CHECKOUT_REQUEST = 'FETCH_CHECKOUT_REQUEST',
  FETCH_CHECKOUT_SUCCESS = 'FETCH_CHECKOUT_SUCCESS',
  FETCH_CHECKOUT_FAILURE = 'FETCH_CHECKOUT_FAILURE',
  BOOK_CHECKOUT_REQUEST = 'BOOK_CHECKOUT_REQUEST',
  BOOK_CHECKOUT_SUCCESS = 'BOOK_CHECKOUT_SUCCESS',
  BOOK_CHECKOUT_FAILURE = 'BOOK_CHECKOUT_FAILURE',
  CHECK_CHECKOUT_REQUEST = 'CHECK_CHECKOUT_REQUEST',
  CHECK_CHECKOUT_SUCCESS = 'CHECK_CHECKOUT_SUCCESS',
  CHECK_CHECKOUT_FAILURE = 'CHECK_CHECKOUT_FAILURE',
  STOP_CHECK_CHECKOUT = 'STOP_CHECK_CHECKOUT',
  SET_CHECKOUT_PACKAGE = 'SET_CHECKOUT_PACKAGE',
  STRIPE_PAYMENT_SECRET_REQUEST = 'STRIPE_PAYMENT_SECRET_REQUEST',
  STRIPE_PAYMENT_SECRET_SUCCESS = 'STRIPE_PAYMENT_SECRET_SUCCESS',
  STRIPE_PAYMENT_SECRET_FAILURE = 'STRIPE_PAYMENT_SECRET_FAILURE',
  UPDATE_CHECKOUT_PACKAGE_PRICE_REQUEST = 'UPDATE_CHECKOUT_PACKAGE_PRICE_REQUEST',
  UPDATE_CHECKOUT_PACKAGE_PRICE_SUCCESS = 'UPDATE_CHECKOUT_PACKAGE_PRICE_SUCCESS',
  UPDATE_CHECKOUT_PACKAGE_PRICE_FAILED = 'UPDATE_CHECKOUT_PACKAGE_PRICE_FAILED',
  BOOK_PAY_AT_HOTEL_REQUEST = 'BOOK_PAY_AT_HOTEL_REQUEST',
  BOOK_PAY_AT_HOTEL_SUCCESS = 'BOOK_PAY_AT_HOTEL_SUCCESS',
  BOOK_PAY_AT_HOTEL_FAILURE = 'BOOK_PAY_AT_HOTEL_FAILURE',
  HOLD_PAY_AT_HOTEL_REQUEST = 'HOLD_PAY_AT_HOTEL_REQUEST',
  SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
  SET_CRYPTO_NETWORK = 'SET_CRYPTO_NETWORK'
}

export enum ConfirmationActionTypes {
  FETCH_CONFIRMATION_SUCCESS = 'FETCH_CONFIRMATION_SUCCESS',
  FETCH_CONFIRMATION_FAILURE = 'FETCH_CONFIRMATION_FAILURE',
  START_CONFIRMATION_POLLING = 'START_CONFIRMATION_POLLING',
  STOP_CONFIRMATION_POLLING = 'STOP_CONFIRMATION_POLLING',
}

export enum AccountActionTypes {
  USER_UPDATE_ERROR_CODE = 'USER_UPDATE_ERROR_CODE',
  UPDATE_ACCOUNT_USER_REQUEST = 'UPDATE_ACCOUNT_USER_REQUEST',
  UPDATE_ACCOUNT_USER_SUCCESS = 'UPDATE_ACCOUNT_USER_SUCCESS',
  UPDATE_ACCOUNT_USER_FAILURE = 'UPDATE_ACCOUNT_USER_FAILURE',
  FETCH_ACCOUNT_BOOKINGS_REQUEST = 'FETCH_ACCOUNT_BOOKINGS_REQUEST',
  FETCH_ACCOUNT_BOOKINGS_SUCCESS = 'FETCH_ACCOUNT_BOOKINGS_SUCCESS',
  FETCH_ACCOUNT_BOOKINGS_FAILURE = 'FETCH_ACCOUNT_BOOKINGS_FAILURE',
  CANCEL_ACCOUNT_BOOKING_REQUEST = 'CANCEL_ACCOUNT_BOOKING_REQUEST',
  CANCEL_ACCOUNT_BOOKING_SUCCESS = 'CANCEL_ACCOUNT_BOOKING_SUCCESS',
  CANCEL_ACCOUNT_BOOKING_FAILURE = 'CANCEL_ACCOUNT_BOOKING_FAILURE',
  SEND_RESET_PASSWORD_LINK_REQUEST = 'SEND_RESET_PASSWORD_LINK_REQUEST',
  SEND_RESET_PASSWORD_LINK_SUCCESS = 'SEND_RESET_PASSWORD_LINK_SUCCESS',
  SEND_RESET_PASSWORD_LINK_FAILURE = 'SEND_RESET_PASSWORD_LINK_FAILURE',
  RESET_ACCOUNT_PASSWORD_REQUEST = 'RESET_ACCOUNT_PASSWORD_REQUEST',
  RESET_ACCOUNT_PASSWORD_SUCCESS = 'RESET_ACCOUNT_PASSWORD_SUCCESS',
  RESET_ACCOUNT_PASSWORD_FAILURE = 'RESET_ACCOUNT_PASSWORD_FAILURE',
  RESET_ERROR_CODE = 'RESET_ERROR_CODE',
  FETCH_USER_INFO_REQUEST = 'FETCH_USER_INFO_REQUEST',
  FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS',
  FETCH_USER_INFO_FAILURE = 'FETCH_USER_INFO_FAILURE',
  USER_SIGN_UP_REQUEST = 'USER_SIGN_UP_REQUEST',
  USER_SIGN_UP_SUCCESS = 'USER_SIGN_UP_SUCCESS',
  USER_SIGN_UP_FAILURE = 'USER_SIGN_UP_FAILURE',
  USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE',
  USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST',
  USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS',
  USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE',
  USER_SETTINGS_CHANGE_PASSWORD_REQUEST = 'USER_SETTINGS_CHANGE_PASSWORD_REQUEST',
  USER_SETTINGS_CHANGE_PASSWORD_SUCCESS = 'USER_SETTINGS_CHANGE_PASSWORD_SUCCESS',
  USER_SETTINGS_CHANGE_PASSWORD_FAILURE = 'USER_SETTINGS_CHANGE_PASSWORD_FAILURE',
  USER_SETTINGS_UPDATE_CONSENTS_REQUEST = 'USER_SETTINGS_UPDATE_CONSENTS_REQUEST',
  USER_SETTINGS_UPDATE_CONSENTS_SUCCESS = 'USER_SETTINGS_UPDATE_CONSENTS_SUCCESS',
  USER_SETTINGS_UPDATE_CONSENTS_FAILURE = 'USER_SETTINGS_UPDATE_CONSENTS_FAILURE',
  CLEAN_UP_USER_META_INFO = 'CLEAN_UP_USER_META_INFO',
  UNSUBSCRIBE_USER_REQUEST = 'UNSUBSCRIBE_USER_REQUEST',
  UNSUBSCRIBE_USER_SUCCESS = 'UNSUBSCRIBE_USER_SUCCESS',
  UNSUBSCRIBE_USER_FAILURE = 'UNSUBSCRIBE_USER_FAILURE',
  SUBSCRIBE_USER_REQUEST = 'SUBSCRIBE_USER_REQUEST',
  SUBSCRIBE_USER_SUCCESS = 'SUBSCRIBE_USER_SUCCESS',
  SUBSCRIBE_USER_FAILURE = 'SUBSCRIBE_USER_FAILURE',
}

export enum TrackingActionTypes {
  TRACK_SEARCH_HOTELS = 'TRACK_SEARCH_HOTELS',
  TRACK_HOTEL_DETAILS = 'TRACK_HOTEL_DETAILS',
  TRACK_CHECKOUT = 'TRACK_CHECKOUT',
  TRACK_BOOK = 'TRACK_BOOK',
  TRACK_BING_CONVERSION = 'TRACK_BING_CONVERSION',
  TRACK_LOGIN = 'TRACK_LOGIN',
  TRACK_REGISTRATION = 'TRACK_REGISTRATION',
  TRACK_HOTEL_CLICK = 'TRACK_HOTEL_CLICK',
  TRACK_ROOM_CLICK = 'TRACK_ROOM_CLICK',
  TRACK_CONFIRM_BOOKING = 'TRACK_CONFIRM_BOOKING',
  TRACK_CHECKOUT_POPUP = 'TRACK_CHECKOUT_POPUP',
}

export const ID_TOKEN_COOKIE_NAME = 'id_token';
export const ACCESS_TOKEN_COOKIE_NAME = 'Authorization';
export const ACCESS_TOKEN_HEADER_NAME = 'Authorization';
export const DEFAULT_TOKEN_EXPIRATION = 7; // day

export enum LoadingTypes {
  IDLE = 'idle',
  RUNNING = 'running',
}

export enum AuthStep {
  SIGN_UP = 'SIGN_UP',
  SIGN_IN = 'SIGN_IN',
  RESET_PASSWORD = 'RESET_PASSWORD',
}

export enum SortingType {
  PRICE_UP = 'Price-asc',
  PRICE_DOWN = 'Price-desc',
  DISCOUNT_DOWN = 'Discount-desc',
  DISTANCE_TO_POINT = 'DistanceToPoint-asc',
  // REVIEW_SCORE = 'Review-desc',
  // MOST_POPULAR = 'Popular-desc',
  TRUST_YOU_RATING = 'TrustYouScore-desc',
  POPULARITY_SCORE = 'PopularityScore-desc',
}

export const GOOGLE_PLACES_COUNTRY_TYPE = 'country';

export const DataElementsId = {
  HEADER_SIGN_IN_BUTTON: 'HEADER_SIGN_IN_BUTTON',
  HEADER_SIGN_UP_BUTTON: 'HEADER_SIGN_UP_BUTTON',
  SIGN_IN_SOCIAL_LOGIN_FACEBOOK: 'SIGN_IN_SOCIAL_LOGIN_FACEBOOK',
  SIGN_IN_SOCIAL_LOGIN_LINKEDIN: 'SIGN_IN_SOCIAL_LOGIN_LINKEDIN',
  SIGN_IN_SOCIAL_LOGIN_GOOGLE: 'SIGN_IN_SOCIAL_LOGIN_GOOGLE',
  SIGN_UP_SOCIAL_LOGIN_FACEBOOK: 'SIGN_UP_SOCIAL_LOGIN_FACEBOOK',
  SIGN_UP_SOCIAL_LOGIN_LINKEDIN: 'SIGN_UP_SOCIAL_LOGIN_LINKEDIN',
  SIGN_UP_SOCIAL_LOGIN_GOOGLE: 'SIGN_UP_SOCIAL_LOGIN_GOOGLE',
  SIGN_IN_BUTTON_FOOTER: 'SIGN_IN_BUTTON_FOOTER',
  SIGN_UP_BUTTON_FOOTER: 'SIGN_UP_BUTTON_FOOTER',
  FORGOT_PASSWORD_BUTTON: 'FORGOT_PASSWORD_BUTTON',
  SIGN_IN_CLOSE_BUTTON: 'SIGN_IN_CLOSE_BUTTON',
  SIGN_UP_CLOSE_BUTTON: 'SIGN_UP_CLOSE_BUTTON',
  SIGN_UP_BANNER: 'SIGN_UP_BANNER',
  HEADER_HOTELS_BUTTON: 'HEADER_HOTELS_BUTTON',
  HEADER_AIRPORT_TRANSFER_BUTTON: 'HEADER_AIRPORT_TRANSFER_BUTTON',
  HEADER_CAR_RENTAL_BUTTON: 'HEADER_CAR_RENTAL_BUTTON',
  HEADER_FLIGHTS_BUTTON: '',
  SEARCH_PANEL_BUTTON: 'SEARCH_PANEL_BUTTON',
  TOP_DESTINATION_BUTTONS: 'TOP_DESTINATION_BUTTONS',
  MAP_RESULT_BUTTON: 'MAP_RESULT_BUTTON',
  APPLY_SORT_BY_BUTTON: 'APPLY_SORT_BY_BUTTON',
  VIEW_SWITCHER_BUTTON: 'VIEW_SWITCHER_BUTTON',
  CLEAR_FILTERS_BUTTON: 'CLEAR_FILTERS_BUTTON',
  CHANGE_PRICE_BY_SLIDER: 'CHANGE_PRICE_BY_SLIDER',
  CHANGE_PRICE_MANUAL: 'CHANGE_PRICE_MANUAL',
  APPLY_HOT_FILTER: 'APPLY_HOT_FILTER',
  APPLY_RATE_FILTER: 'APPLY_RATE_FILTER',
  CHANGE_DISTANCE_BY_SLIDER: 'CHANGE_DISTANCE_BY_SLIDER',
  CHANGE_DISTANCE_MANUAL: 'CHANGE_DISTANCE_MANUAL',
  APPLY_HOTEL_RATING: 'APPLY_HOTEL_RATING',
  CHOOSE_HOTEL_FACILITIES: 'CHOOSE_HOTEL_FACILITIES',
  CHOOSE_RECOMENDATIONS: 'CHOOSE_RECOMENDATIONS',
  SEARCH_HOTEL_PREVIEW_BUTTON: 'SEARCH_HOTEL_PREVIEW_BUTTON',
  SEARCH_HOTEL_CHOOSE_BUTTON: 'SEARCH_HOTEL_CHOOSE_BUTTON',
  DETAIL_HOTEL_PREVIEW_BUTTON: 'DETAIL_HOTEL_PREVIEW_BUTTON',
  CHANGE_VISITORS_BUTTON: 'CHANGE_VISITORS_BUTTON',
  MAP_HOTEL_DETAIL_BUTTON: 'MAP_HOTEL_DETAIL_BUTTON',
  HOTEL_BOOK_BUTTON: 'HOTEL_BOOK_BUTTON',
  HOTEL_ROOM_LIST_SHOW_MORE_BUTTON: 'HOTEL_ROOM_LIST_SHOW_MORE_BUTTON',
  CHECKOUT_CANCELLATION_POLICY: 'CHECKOUT_CANCELLATION_POLICY',
  CHECKOUT_TERMS_AND_CONDITIONS: 'CHECKOUT_TERMS_AND_CONDITIONS',
  CHECKOUT_PAGE_PAY_BUTTON: 'CHECKOUT_PAGE_PAY_BUTTON',
  FAIL_BOOKING_TRY_AGAIN_BUTTON: 'FAIL_BOOKING_TRY_AGAIN_BUTTON',
};

export enum BedTypes {
  UNKNOWN,
  DOUBLE,
  TWINS_BED,
}

export enum CreditCardTypes {
  ImmediatePayment,
  Guarantee,
  // Deposit,
  NotRequired = 3,
}
