interface PaymentSecretRequest {
  offerKey: string;
  byBitDiscount: boolean;
}

export type StripePaymentSecretRequest = PaymentSecretRequest;

export interface StripePaymentSecretResponse {
  clientSecret: string;
}

export enum StripePaymentMethodType {
  StripeCard = 'StripeCard',
}

export type TPaymentMethodTypes = StripePaymentMethodType;
