const LogoLarge = () => (
  <svg width="400" height="39" viewBox="0 0 400 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11297_19524)">
    <mask id="mask0_11297_19524" maskUnits="userSpaceOnUse" x="0" y="0" width="400" height="39">
    <path d="M399.648 0.898438H0.00292969V38.1077H399.648V0.898438Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_11297_19524)">
    <mask id="mask1_11297_19524" maskUnits="userSpaceOnUse" x="2" y="3" width="396" height="33">
    <path d="M397.385 3.39648H2.49805V35.9546H397.385V3.39648Z" fill="white"/>
    </mask>
    <g mask="url(#mask1_11297_19524)">
    <mask id="mask2_11297_19524" maskUnits="userSpaceOnUse" x="2" y="3" width="396" height="33">
    <path d="M397.383 3.38867H2.49561V35.9468H397.383V3.38867Z" fill="white"/>
    </mask>
    <g mask="url(#mask2_11297_19524)">
    <mask id="mask3_11297_19524" maskUnits="userSpaceOnUse" x="2" y="3" width="396" height="33">
    <path d="M397.383 3.38086H2.49561V35.939H397.383V3.38086Z" fill="white"/>
    </mask>
    <g mask="url(#mask3_11297_19524)">
    <path d="M7.31952 17.4763H25.5648V21.4204H7.31952V17.4763ZM7.77565 31.9073H28.4535V35.9442H2.70752V3.46289H27.7442V7.49986H7.77565V31.9073ZM54.3987 35.9442V3.46289H58.5542L82.0197 30.144H79.8404V3.46289H84.9088V35.9442H80.7527L57.2872 9.26313H59.4666V35.9442H54.3987ZM120.311 35.9442V7.49986H108.147V3.46289H137.491V7.49986H125.328V35.9442H120.311ZM160.75 35.9442V3.46289H174.586C177.694 3.46289 180.347 3.91144 182.543 4.80855C184.739 5.70565 186.428 7.00491 187.611 8.70634C188.793 10.4077 189.385 12.4339 189.385 14.785C189.385 17.136 188.793 19.1622 187.611 20.8636C186.428 22.5341 184.739 23.8179 182.543 24.7149C180.347 25.6121 177.694 26.0606 174.586 26.0606H163.538L165.818 23.9261V35.9442H160.75ZM184.469 35.9442L175.447 24.1581H180.87L189.993 35.9442H184.469ZM165.818 24.3901L163.538 22.1165H174.434C177.677 22.1165 180.127 21.4823 181.783 20.214C183.472 18.9148 184.317 17.1051 184.317 14.785C184.317 12.4648 183.472 10.6707 181.783 9.40234C180.127 8.13401 177.677 7.49986 174.434 7.49986H163.538L165.818 5.17976V24.3901ZM209.939 35.9442L226.005 3.46289H231.023L247.138 35.9442H241.819L227.475 6.06141H229.502L215.159 35.9442H209.939ZM216.781 27.8239L218.15 24.1117H238.118L239.587 27.8239H216.781ZM278.527 35.9442L262.918 3.46289H268.392L282.734 33.4386H279.592L294.034 3.46289H299.104L283.543 35.9442H278.527ZM325.979 17.4763H344.224V21.4204H325.979V17.4763ZM326.434 31.9073H347.111V35.9442H321.365V3.46289H346.402V7.49986H326.434V31.9073ZM373.056 35.9442V3.46289H378.125V31.9073H397.333V35.9442H373.056Z" fill="black"/>
    </g>
    </g>
    </g>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_11297_19524">
    <rect width="400" height="37.2093" fill="white" transform="translate(0 0.894531)"/>
    </clipPath>
    </defs>
  </svg>
)
      
export default LogoLarge;
      