import 'styled-components/macro';

import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { I18n } from 'src/components/I18n';
import { L10nLink } from 'src/components/common/Link';
import useToggler from 'src/hooks/useToggler';
import useMatchMedia from 'src/hooks/useMatchMedia';

import Container from '../common/Container';
import { Button } from '../common/WrappedButtons';

const StyledButton = styled(Button)(() => {
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));
  
  return `
    font-size: ${isMobile ? "14px" : "inherit"};
    min-width: 90px;
    border-radius: 16px !important;
    background: #000 !important;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    font-weight: 400 !important;
    margin-left: 0 !important;

    &:hover,
    &:focus {
      background: #000;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    }
    `});

const ACCEPT_COOKIE_POLICY = 'acceptCookiePolicy';

const cookiesStorage = {
  getCookiesBanner: () => {
    try {
      return localStorage.getItem(ACCEPT_COOKIE_POLICY) === 'true';
    } catch (e) {
      console.warn(e);
      return true;
    }
  },
  setCookiesBanner: (value: 'true' | 'false') => {
    try {
      return localStorage.setItem(ACCEPT_COOKIE_POLICY, value);
    } catch (e) {
      console.warn(e);
    }
  },
};

const { getCookiesBanner, setCookiesBanner } = cookiesStorage;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: ${({ theme }) => theme.customShadows.widget};

  ${Container} {
    margin: ${({ theme }) => theme.spacing(4)} auto;
  }
`;

export const CookiesBanner: FC = () => {
  //TODO: replace it by redux-persist
  const didUserSeeCookiesBanner = getCookiesBanner();

  const { toggler, handleClose } = useToggler({
    defaultValue: !didUserSeeCookiesBanner,
  });

  const onCloseClick = () => {
    handleClose();
    setCookiesBanner('true');
  };

  return (
    <Fade in={toggler} unmountOnExit mountOnEnter>
      <Wrapper>
        <Container>
          <Stack
            spacing={4}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body4">
              <I18n
                id="COOKIES_TEXT_BANNER"
                values={{
                  privacypolicylink: (msg: string) => (
                    <L10nLink
                      to="/privacy-policy"
                      target="_blank"
                      variant="inherit"
                      css={`
                        /* font-weight: 600; */
                      `}
                    >
                      {msg}
                    </L10nLink>
                  ),
                }}
              />
            </Typography>
            <div>
              <StyledButton onClick={onCloseClick}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                > 
                 I accept
              </StyledButton>
            </div>
          </Stack>
        </Container>
      </Wrapper>
    </Fade>
  );
};
