import * as yup from 'yup';
import _isUndefined from 'lodash/isUndefined';

import { I18n, I18nProps } from 'src/components/I18n';
import { StripePaymentMethodType } from 'src/models/payments';
import { isCreditCard } from 'src/components/pages/Checkout/PaymentPanel/paymentMethods';
import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';

import {
  ErrorCode,
  getErrorCodeNameBy,
  serverErrorTranslations,
  ServerErrorCodesType,
  MappedErrorMessageType,
} from './types';

const getI18nError =
  (errorI18nId: I18nProps['id'], options: Omit<I18nProps, 'id'> = {}) =>
  () =>
    <I18n id={errorI18nId} {...options} />;

export const fieldRequired = yup
  .string()
  .required(getI18nError('GENERAL_FIELD.REQUIRED'));

export const onlyNumbersAllowed = yup
  .string()
  .matches(/^\d*$/, getI18nError('GENERAL_FIELD.ONLY_NUMBERS_ALLOWED'));

export const lengthValidator = (
  message: DefaultAppMessagesTypeKey,
  limit: number
) =>
  yup
    .string()
    .max(limit, getI18nError(message, { values: { numOfChars: limit } }))
    .required(getI18nError('GENERAL_FIELD.REQUIRED'));

export const passwordRequired = yup
  .string()
  .required(getI18nError('SIGN_UP_FORM_VALIDATION.PASSWORD.REQUIRED'));

export const referralCodeRequired = yup
  .string()
  .required(getI18nError('SIGN_UP_FORM_VALIDATION.REFERRAL_CODE.REQUIRED'));

export const confirmationCodeRequired = yup
  .string()
  .required(getI18nError('SIGN_UP_FORM_VALIDATION.CONFIRMATION_CODE.REQUIRED'));

export const emailValidation = yup
  .string()
  .email(getI18nError('SIGN_UP_FORM_VALIDATION.EMAIL.VALID'))
  .required(getI18nError('SIGN_UP_FORM_VALIDATION.EMAIL.REQUIRED'));

export const passwordValidation = yup
  .string()
  .min(
    8,
    getI18nError('SIGN_UP_FORM_VALIDATION.PASSWORD.MIN_CHARACTERS', {
      values: { numOfChars: 8 },
    })
  )
  .matches(
    /[A-Z]/,
    getI18nError('SIGN_UP_FORM_VALIDATION.PASSWORD.ONE_UPPER_CASE')
  )
  .matches(
    /[a-z]/,
    getI18nError('SIGN_UP_FORM_VALIDATION.PASSWORD.ONE_LOWER_CASE')
  )
  .matches(/[0-9]/, getI18nError('SIGN_UP_FORM_VALIDATION.PASSWORD.ONE_NUMBER'))
  .required(getI18nError('SIGN_UP_FORM_VALIDATION.PASSWORD.REQUIRED'));

export const confirmPasswordValidation = passwordValidation.oneOf(
  [yup.ref('newPassword')],
  getI18nError('USER.FORM.VALIDATION.ERROR.NAME.PASSWORDS_DONOT_MATCH')
);

export const phoneValidation = yup.string().matches(/^\+[0-9]{1,22}$/, {
  message: getI18nError('ERROR.PHONE_FORMAT'),
  excludeEmptyString: true,
});

export const phoneValidationStrict = phoneValidation.required(
  getI18nError('GENERAL_FIELD.REQUIRED')
);

export const intlPhoneValidation = yup
  .object()
  .test({
    name: 'format',
    test: ({ phoneNumber, isValid }, methods) => {
      if (!phoneNumber) {
        return methods.createError({ path: "phone", message: getI18nError('GENERAL_FIELD.REQUIRED'), });
      }
      if (!isValid) {
        return methods.createError({ path: "phone", message: getI18nError('GENERAL_FIELD.INCORRECT_FORMAT'), });
      }

      return true;
    }})

export const intlPhoneValidationStrict = yup.object().test(
  'notEmpty',
  getI18nError('GENERAL_FIELD.REQUIRED'),
  ({ phoneNumber }) => Boolean(phoneNumber)
);

export const onlyLatinValidation = yup
  .string()
  .matches(/^[A-Za-z]+([\s-]*[A-Za-z]+)*$/, {
    message: getI18nError('ERROR.ONLY_LETTERS'),
    excludeEmptyString: true,
  });

export const countryValidation = yup.object().shape({
  countryCode: fieldRequired,
});

export const namesValidation = yup.object().shape({
  firstName: onlyLatinValidation.required(
    getI18nError('GENERAL_FIELD.REQUIRED')
  ),
  lastName: onlyLatinValidation.required(
    getI18nError('GENERAL_FIELD.REQUIRED')
  ),
});

export const cardFieldValidation = yup.string().when('paymentMethodType', {
  is: (methodType: StripePaymentMethodType) => isCreditCard(methodType),
  then: fieldRequired.matches(/^valid$/, ({ value }) => value),
});

export const arrayValidationWithSchema = (schema: yup.ObjectSchema<any>) =>
  yup.array().of(schema);

export const serverErrorMessageRenderer = (errorCode?: ErrorCode) => () => {
  if (_isUndefined(errorCode)) {
    return false;
  }

  return <I18n id={getErrorCodeNameBy(errorCode)} />;
};

export const mapServerErrorTranslations = (
  errors?: ServerErrorCodesType
): MappedErrorMessageType | undefined => {
  if (!errors) {
    return;
  }
  const serverErrors = Object.keys(errors);
  if (serverErrors.length === 0) {
    return;
  }
  return serverErrors.reduce((result, item: any) => {
    const errorItem = {
      [item]: serverErrorTranslations[errors[item]],
    };
    return {
      ...result,
      ...errorItem,
    };
  }, {});
};
