import { VFC } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';

import Container from 'src/components/common/Container';
import useMatchMedia from 'src/hooks/useMatchMedia';
import LinksStack, { StackLink } from 'src/components/common/LinksStack';
import Logo from 'src/components/common/Logo';
import { HEADER_HEIGHT_VARIABLE } from 'src/components/common/GlobalStyle';
import { useMatchPage } from 'src/hooks/useMatchPage';
import { CurrencyButton as DesktopCurrencyButton } from 'src/components/Panels/HeaderPanel/ModalMenu/CurrencyButton';
import { userSelector } from 'src/store/user/selectors';
import ByBitWhiteIcon from 'src/assets/icons/bybit-white';
import useConfig from 'src/hooks/swr/useConfig';

import { AuthBlock } from './ModalMenu/AuthBlock';

const HeaderWrapper = styled.header<P>`
  background: ${({ theme, $isContrast }) =>
    $isContrast ? 'transparent' : '#121212'};
  position: relative;
  z-index: 4;
`;

const HeaderContainer = styled(Container)`
  height: ${HEADER_HEIGHT_VARIABLE};
  display: grid;
`;

const SupportButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  cursor: pointer;
  color: #fff;
`

type P = { $isContrast: boolean };

const StyledStack = styled(Stack)<P>`
  ${({ theme, $isContrast }) =>
    $isContrast && {
      [theme.breakpoints.down('sm')]: { color: theme.palette.common.white },
      padding: '40px 0',
    }}
`;

const ByBitInfo = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
`

const NewLabel = styled.span`
  display: flex;
  height: 24px;
  padding: 2px 10px;
  align-items: center;
  gap: 4px;
  border-radius: 30px;
  background: #FFF;
  color: #000000;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 4px;
`

const ByBitIconWrapper = styled.span`
  margin-top: 2px;
`

const HeaderPanel: VFC = () => {
  const theme = useTheme();
  const { isAuthenticated } = useSelector(userSelector);
  const { data: config } = useConfig();

  const isDesktop = useMatchMedia(theme.breakpoints.up('md'));

  const isContrast = useMatchPage('/');

  const handleClickSupport = () => {
    window.open('/contact');
  }

  const links: StackLink[] = [];
  const color = isContrast ? '#000000' : '#FFF';

  const Text = styled.div`
    color: ${color};
  `;

  return (
    <HeaderWrapper $isContrast={isContrast}>
      <HeaderContainer disableMargin>
        <StyledStack
          spacing={2}
          justifyContent={{ xs: 'space-between', md: 'space-between' }}
          alignItems="center"
          direction="row"
          position="relative"
          $isContrast={isContrast}
        >
          <Stack alignItems="center" spacing={12} direction="row">
            <Logo />
            {isDesktop && (
              <LinksStack spacing={9} direction="row" links={links} />
            )}
          </Stack>
          {/* {isDesktop && isContrast && config?.featureToggles.ShowByBit ? (
            <Stack>
              <ByBitInfo>
                <NewLabel>
                  New
                </NewLabel>
                Pay with
                <ByBitIconWrapper>
                  <ByBitWhiteIcon />
                </ByBitIconWrapper>
                & get up to 6% cashback
              </ByBitInfo>
            </Stack>
          ) : null} */}
          <Stack
            spacing={8}
            direction="row"
            alignItems="center"
            position={{ xs: 'absolute', md: 'initial' }}
            right={0}
          >
            {isAuthenticated && isDesktop ? (
              <SupportButton         
                color="buttonSecondary"
                onClick={handleClickSupport}
              >
                Support
              </SupportButton>
            ) : null}
            
            {isDesktop ? <DesktopCurrencyButton isContrast={false} /> : null}
            <AuthBlock isContrast={false} />
          </Stack>
        </StyledStack>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default HeaderPanel;
