import { VFC } from 'react';
import { useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components/macro';

import { Button } from 'src/components/common/WrappedButtons';
import { I18n } from 'src/components/I18n';
import { userInfoSelector } from 'src/store/user/selectors';
import useMatchMedia from 'src/hooks/useMatchMedia';

interface IAccountButton {
  onClick: () => void;
  isContrast?: boolean;
}

const StyledButton = styled(Button)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;

  & span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
  }
`;

export const AccountButton: VFC<IAccountButton> = ({ onClick, isContrast }) => {
  const { isVip } = useSelector(userInfoSelector);
  const color = isContrast ? '#000000' : '#FFF';
  const theme = useTheme();
  const isTablet = useMatchMedia(theme.breakpoints.down('md'));

  const Text = styled.div`
    color: ${color};
  `;

  if (!isTablet) {
    return (
      <StyledButton
        color="buttonSecondary"
        size="small"
        onClick={onClick}
        startIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M5.5 5.75C5.5 6.67826 5.86875 7.56849 6.52513 8.22487C7.1815 8.88125 8.07174 9.25 9 9.25C9.92826 9.25 10.8185 8.88125 11.4749 8.22487C12.1313 7.56849 12.5 6.67826 12.5 5.75C12.5 4.82174 12.1313 3.9315 11.4749 3.27513C10.8185 2.61875 9.92826 2.25 9 2.25C8.07174 2.25 7.1815 2.61875 6.52513 3.27513C5.86875 3.9315 5.5 4.82174 5.5 5.75ZM14 15.25H3.5C3.36739 15.25 3.24021 15.1973 3.14645 15.1035C3.05268 15.0098 3 14.8826 3 14.75V13.25C3 12.587 3.26339 11.9511 3.73223 11.4822C4.20107 11.0134 4.83696 10.75 5.5 10.75H12.5C13.163 10.75 13.7989 11.0134 14.2678 11.4822C14.7366 11.9511 15 12.587 15 13.25V14.75C15 14.8826 14.9473 15.0098 14.8536 15.1035C14.7598 15.1973 14.6326 15.25 14.5 15.25H14Z"
              fill={color}
            />
          </svg>
        }
      >
        <Text>
          <I18n
            id={
              isVip
                ? 'MAIN_NAV_MENU.SECTION_HEADER.VIP_ACCOUNT'
                : 'MAIN_NAV_MENU.SECTION_HEADER.ACCOUNT'
            }
          />
        </Text>
      </StyledButton>
    );
  } else {
    return (
      <StyledButton
        color="buttonSecondary"
        size="small"
        onClick={onClick}
        startIcon={
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 5H22V7H2V5ZM2 11H22V13H2V11ZM22 17H2V19H22V17Z"
                  fill={color} />
          </svg>
        }
      >
      </StyledButton>
    );
  }
};