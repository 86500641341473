import _merge from 'lodash/merge';
import _get from 'lodash/get';

declare const window: Window & {
  CLICKTRIP: {
    config: any;
  };
};

/**
 * All default configuration variables should be placed here
 */
// const oldDefaultConfig = {
//   ENV: 'local',
//   TRUST_PILOT_API_KEY: '2AibMhVJdpF87V2sia6wVPVELVwZzP6m',
//   GOOGLE_API_KEY: 'AIzaSyBpfmWFV15shKcPsCipa6vpYt3oT5SwAwU',
//   STRIPE_API_KEY: 'pk_test_EZeyNl0I34ud7nJBo7ojte0r',
//   GOOGLE_MAP_API_KEY: 'AIzaSyBAvVq3V-wos8uThehNjcMT8PhSE7UIzxE',
//   GOOGLE_RECAPTCHA_KEY: '6LeENtAUAAAAAMfWYblaE7d35elEjMN2Ds1qdIHb',
//   CDN_HOST: 'cdn-dbe.clicktrip.dev',
//   SHA_VERSION: '',
//   SAFE_CHARGE_env: 'test',
//   SAFE_CHARGE_host: 'https://ppp-test.safecharge.com/',
//   SAFE_CHARGE_merchantId: '4119658267675541203',
//   SAFE_CHARGE_merchantSiteId: '184833',
// };

const defaultConfig = {
  ENV: 'local',
  TRUST_YOU_API_KEY: '24dc6ebf-64f7-43c8-b799-b65b7bc46691',
  REACT_APP_API_BASE_URL: 'https://entravel.com/api',
  GOOGLE_API_KEY: 'AIzaSyBFY8CS3ZCUZZhT7fmTfCEzXlg7GkgnCps',
  GOOGLE_RECAPTCHA_KEY: '6LddMkoqAAAAAJCdR2W0YnG05Razxv_tCzITeMMs',
  // STRIPE_API_KEY:
  //   'pk_test_51PU4ZdP3fb6z7uvmskhHTgmJSIJVN9bnrsluH8eeZU9Bc1RuXAxcUsv3XbX7zRf7iGk8jobo2wuwcC2PcTpRsJzB001e2fzE4r',
  STRIPE_API_KEY:
    'pk_live_51PU4ZdP3fb6z7uvmsNpiVR9s4OLHFt6qWXqhBLlXvx01qHPJUZKrnaB8uxdBdfuuUVhwYjC0n1AhwSlE3ZrBBWDB00W7virrLd',
  CDN_HOST: 'cdn-dbe.clicktrip.dev',
  SHA_VERSION: '',
  //ONEALL_HOST: 'clicktrip.api.oneall.com',
};

let effectiveConfig: any = null;

/**
 * Resolves configuration variables
 */
export const appConfig = (name: string): string => {
  if (!effectiveConfig) {
    effectiveConfig = _merge(
      {},
      defaultConfig,
      (window.CLICKTRIP || {}).config
    );
  }
  return _get(effectiveConfig, name);
};
